@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {

    #reactApp ul {
        @apply trpr-list-none;
    }

    #reactApp li {
        @apply trpr-list-none;
    }

    #reactApp body {
        @apply trpr-font-open-sans;
    }

    #reactApp p {
        @apply trpr-font-open-sans trpr-text-[17px] trpr-leading-[27.2px]
    }

    #reactApp h1 {
        @apply trpr-text-[44px] trpr-font-roboto-slab trpr-font-bold;

    }

    #reactApp h2 {
        @apply trpr-text-[32px] trpr-font-roboto-slab trpr-font-bold;

    }

    #reactApp h3 {
        @apply trpr-text-[28px] trpr-font-roboto-slab trpr-font-bold;

    }

    #reactApp h4 {
        @apply trpr-text-2xl trpr-font-roboto-slab trpr-font-bold;

    }

    #reactApp h5 {
        @apply trpr-text-xl trpr-font-open-sans trpr-font-normal;

    }

    #reactApp h6 {
        @apply trpr-text-xl trpr-font-roboto-slab trpr-font-bold;

    }

    #reactApp a {
        @apply trpr-text-dno-industry-primary-800-accessible trpr-font-bold hover:trpr-underline
    }

    #reactApp input[type=text] {
        @apply trpr-p-2 trpr-bg-white trpr-text-neutral-600 trpr-box-border trpr-border-neutral-400 trpr-border-2 trpr-rounded;
    }

    #reactApp textarea {
        @apply trpr-p-2 trpr-text-neutral-600 trpr-box-border trpr-border-neutral-400 trpr-border-2 trpr-rounded focus:trpr-text-neutral-800 focus:trpr-border-neutral-900;
    }

}

@layer components {

    .dno-body-large {
        @apply text-[22px] font-normal;

    }

    .btn-dno-sm {
        @apply shadow-button-shadow rounded my-auto px-2 h-8 text-xs text-white bg-dno-industry-primary-800-accessible
        hover:bg-dno-industry-primary-700-main focus:border-dno-industry-primary-900 focus:border-2 active:bg-dno-industry-primary-900;

    }

    .btn-dno-md {
        @apply shadow-button-shadow rounded h-10 text-white  my-auto px-2 bg-dno-industry-primary-800-accessible
        hover:bg-dno-industry-primary-700-main focus:border-dno-industry-primary-900 focus:border-2
        active:bg-dno-industry-primary-900;
    }

    .btn-dno-lg {
        @apply shadow-button-shadow rounded-md my-auto px-3 h-12 text-lg text-white
        bg-dno-industry-primary-800-accessible hover:bg-dno-industry-primary-700-main
        focus:border-dno-industry-primary-900 focus:border-2 active:bg-dno-industry-primary-900;

    }

    .btn-dno-white-sm {
        @apply shadow-button-shadow rounded my-auto px-2 h-8 text-xs bg-white text-neutrals-dno-granite
        border-neutrals-dno-granite border-2 hover:bg-neutral-200 active:bg-neutral-800 active:text-white
    }

    .btn-dno-white-md {
        @apply shadow-button-shadow rounded h-10 text-white  my-auto px-2 bg-white text-neutrals-dno-granite
        border-neutrals-dno-granite border-2 hover:bg-neutral-200 active:bg-neutral-800 active:text-white
    }

    .btn-dno-white-lg {
        @apply shadow-button-shadow rounded-md my-auto px-3 h-12 text-lg
        bg-white text-neutrals-dno-granite border-neutrals-dno-granite border-2 hover:bg-neutral-200
        active:bg-neutral-800 active:text-white
    }

    .btn-dno-dark-sm {
        @apply shadow-button-shadow rounded my-auto px-2 h-8 text-xs
        bg-neutral-900 text-white border-white border-2 hover:bg-white hover:text-neutral-900
        hover:border-neutral-900 active:bg-neutral-800
    }

    .btn-dno-dark-md {
        @apply shadow-button-shadow rounded h-10 text-white  my-auto px-2
        bg-neutral-900 text-white border-white border-2 hover:bg-white hover:text-neutral-900
        hover:border-neutral-900 active:bg-neutral-800

    }

    .btn-dno-dark-lg {
        @apply shadow-button-shadow rounded-md my-auto px-3 h-12 text-lg
        bg-neutral-900 text-white border-white border-2 hover:bg-white hover:text-neutral-900 hover:border-neutral-900
        active:bg-neutral-800

    }

    .card-div {
        @apply hover:shadow-hover-shadow
    }

    .modal {
        box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
        background-color: rgba(0, 0, 0, 0.8) ;
        background-color: rgba(0, 0, 0, 0.8) ;
        position: fixed;
        overflow: hidden;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index:99999999999999999999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        padding-top:10vh;
        padding-bottom: 10vh;
    }

    .noscroll {
        height: 100% !important;
        overflow: auto !important;
    }
@import "~react-image-gallery/styles/css/image-gallery.css";

}
